<template>
  <div>
    <!-- 头部 -->
    <section class="section p0 panel header_video">
      <div class="col-lg-12 p0 container_max">
        <video
          autoplay
          muted
          playsInline="{true}"
          class="video_box"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-playsinline="true"
          x5-video-orientation="portraint"
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/renz.mp4"
        ></video>

        <h1 class="T128px_Regular colorfff header_title wow animate__fadeInUp">
          {{ $t("ninja.headerTitle") }}
        </h1>

        <div class="header_video_bottom flex-wb">
          <div class="left_box col-lg-6">
            <header class="T36px_Regular colorfff">
              <span class="jb_title">{{ $t("ninja.jb_title1") }}</span>
              <span class="xian"></span>
              <span>{{ $t("ninja.jb_title2") }}</span>
            </header>
            <div class="T18px_Regular colorfff left_box_tips">
              {{ $t("ninja.left_box_tips") }}
            </div>
          </div>
          <div
            class="right_box col-lg-6 flex-wb"
            style="align-items: flex-start"
          >
            <div
              class="col-lg-4 flex-cc"
              v-for="(item, index) in headerList"
              :key="index"
            >
              <header class="right_box_header">
                <img :src="item.url" alt="" style="width: 1.6rem" />
                <span
                  class="T24px_Regular colorfff max_title"
                  :style="
                    index == 2
                      ? 'border-right: .0313rem solid transparent;'
                      : ''
                  "
                  >{{ item.title }}</span
                >
              </header>
              <div class="T18px_Regular colorfff flex-c chlie_box">
                <span v-for="(items, ins) in item.chlie" :key="ins">{{
                  items
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 项目背景 -->
    <section class="section p0 project_bj_box">
      <div class="container">
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF">
            {{ $t("ninja.project_bj_title1") }}
          </span>
          <span class="colorfff">
            {{ $t("ninja.project_bj_title2") }}
          </span>
        </header>
        <div
          class="proj_bj_text proj_bj_p1 T16px_Regular colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {{ $t("ninja.project_bj_p1") }}
        </div>
        <div
          class="proj_bj_text T16px_Regular colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".25s"
        >
          {{ $t("ninja.project_bj_p2") }}
        </div>

        <div class="bj_image1">
          <img
            class="bj_image"
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/bj1.png"
            alt=""
          />
        </div>

        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF"> {{ $t("ninja.project_bj1_header") }}</span>
          <span class="colorfff">{{ $t("ninja.project_bj2_header") }}</span>
        </header>
        <div
          class="proj_bj_text proj_bj_p1 T16px_Regular colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {{ $t("ninja.project_bj1_p1") }}
        </div>
        <div
          class="proj_bj_text T16px_Regular colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".25s"
        >
          {{ $t("ninja.project_bj1_p2_1") }}
          <span class="color2C6A67">#2C6A67</span>
          {{ $t("ninja.project_bj1_p2_2") }}
          <span class="colorED911F">#ED911F</span>
          {{ $t("ninja.project_bj1_p2_3") }}
        </div>

        <div class="bj_image1">
          <!-- <img
            class="bj_image"
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/bj2.png"
            alt=""
          /> -->
          <video
            autoplay
            muted
            loop
            playsInline="{true}"
            class="bj_image"
            webkit-playsinline="true"
            x-webkit-airplay="true"
            x5-playsinline="true"
            x5-video-orientation="portraint"
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/Comp1.mp4"
          ></video>
        </div>
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF"> {{ $t("ninja.project_bj3_header") }}</span>
          <span class="colorfff">{{ $t("ninja.project_bj4_header") }}</span>
        </header>
        <div
          class="proj_bj_text proj_bj_p1 T16px_Regular colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {{ $t("ninja.project_bj2_p1") }}
        </div>
        <div
          class="proj_bj_text T16px_Regular colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".25s"
        >
          <span class="T24px_Regular">
            {{ $t("ninja.project_bj2_p2_title") }}
          </span>
          {{ $t("ninja.project_bj2_p2") }}
        </div>
        <div class="bj_image2">
          <img
            class="bj_image"
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/bj3.png"
            alt=""
          />
        </div>

        <div
          class="proj_bj_text T16px_Regular colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          <span class="T24px_Regular">
            {{ $t("ninja.project_bj2_p3_title") }}
          </span>

          {{ $t("ninja.project_bj2_p3") }}
        </div>
        <div class="bj_image2">
          <img
            class="bj_image"
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/bj4.png"
            alt=""
          />
        </div>
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF">
            {{ $t("ninja.project_bj5_header") }}
          </span>
          <span class="colorfff">{{ $t("ninja.project_bj6_header") }}</span>
        </header>
        <div class="bj_image3">
          <!-- <img
            class="bj_image"
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/bj5.png"
            alt=""
          /> -->
          <video
            autoplay
            muted
            loop
            playsInline="{true}"
            class="bj_image"
            webkit-playsinline="true"
            x-webkit-airplay="true"
            x5-playsinline="true"
            x5-video-orientation="portraint"
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/ninja logo transformation.mp4"
          ></video>
        </div>
        <div class="bj_image4">
          <img
            class="bj_image"
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/bj6.png"
            alt=""
          />
        </div>
      </div>
    </section>

    <!-- 项目挑战 -->
    <section class="section project_challenge_box">
      <div class="container">
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF">
            {{ $t("ninja.project_bj7_header") }}
          </span>
          <span class="colorfff">{{ $t("ninja.project_bj8_header") }}</span>
        </header>
      </div>

      <!-- <div class="carousel_box flex-c col-12">
        <el-carousel indicator-position="outside" type="card" :autoplay="false">
          <el-carousel-item v-for="(item, index) in carouselList" :key="index">
            <div class="flex-cc carousel_item">
              <img :src="item.url" class="carousel_img" alt="" />
              <span class="T12px_Regular colorfff">{{ item.title }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div> -->
      <div class="project_bj_image">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide
            v-for="item in carouselList"
            :key="item"
            class="swiper-slideb"
          >
            <div class="img_box carousel_item flex-cc">
              <img
                :src="item.url"
                class="img_box_image"
                :class="`round${item}_image`"
                alt=""
              />
              <span class="T12px_Regular colorfff title_b">{{
                item.title
              }}</span>
            </div>
          </swiper-slide>
        </swiper>
        <img
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/swiper_arr.png"
          class="btn-left swiper_arr_icon"
          alt=""
        />
        <img
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/swiper_arr.png"
          class="btn-right swiper_arr_icon"
          alt=""
        />
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <!-- 项目总结 -->
    <section class="section project_summarize_box">
      <div class="container">
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF"> {{ $t("ninja.project_summarize1") }}</span>
          <span class="colorfff"> {{ $t("ninja.project_summarize2") }}</span>
        </header>
        <div
          class="proj_bj_text proj_bj_p1 T16px_Regular colorfff wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {{ $t("ninja.project_summarize_proj_bj_text") }}
        </div>
      </div>

      <div class="customized_box flex-wb">
        <div class="flex-c">
          <span class="T36px_Regular colorfff">
            {{ $t("customized.title") }}
            <span class="color1D73FF">{{ $t("customized.title1") }}</span>
          </span>
          <span class="T16px_Regular color818181">
            {{ $t("customized.tipe") }}
          </span>
        </div>
        <div class="flex-c">
          <span class="T14px_Regular colorfff tips_net">
            {{ $t("customized.tipe_ri") }}
          </span>
          <span
            @click="routerBtn('/contact')"
            class="T20px_Regular colorfff my_btn animation_right"
          >
            {{ $t("customized.btn") }}
          </span>
        </div>
      </div>

      <div class="case_box">
        <header class="case_header T36px_Regular colorfff">
          {{ $t("moXun.genduo") }}
        </header>
        <div class="case_list flex-w col-12">
          <div
            class="case_item flex-c col-4"
            @click="routerBtn(item.viewUrl)"
            v-for="(item, index) in caseList"
            :key="index"
          >
            <div class="case_image_box">
              <img :src="item.url" class="case_image" alt="" />
            </div>
            <span class="T24px_Regular colorfff case_title">
              {{ item.title }}
            </span>
            <span class="T14px_Regular colorfff case_tips">
              {{ item.tips }}
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);

import { WOW } from "wowjs";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 30,
        // loop: true,
        autoplay: {
          // delay: 3000,
          // stopOnLastSlide: false,
          // disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          //下一张
          nextEl: ".btn-right", //下一张标签类名可以自定义
          //上一张
          prevEl: ".btn-left", //上一张标签类名可以自定义
        },
      },
      headerList: [
        {
          title: this.$t("ninja.headerList1_title"),
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/s1.png",
          chlie: [
            this.$t("ninja.headerList1_chlie1"),
            this.$t("ninja.headerList1_chlie2"),
            this.$t("ninja.headerList1_chlie3"),
            this.$t("ninja.headerList1_chlie4"),
          ],
        },
        {
          title: this.$t("ninja.headerList2_title"),
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/s2.png",
          chlie: [this.$t("ninja.headerList2_chlie1")],
        },
        {
          title: this.$t("ninja.headerList3_title"),
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/s3.png",
          chlie: [this.$t("ninja.headerList3_chlie1")],
        },
      ],

      carouselList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/photo1.png",
          title: this.$t("ninja.carouselList1_title"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/photo2.png",
          title: this.$t("ninja.carouselList1_title"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/photo3.png",
          title: this.$t("ninja.carouselList1_title"),
        },
      ],

      caseList: [
        // {
        //   url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/case1.png",
        //   title: "忍者X 学院",
        //   tips: "Train Like Ninja",
        //   viewUrl: "/ninja",
        // },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/moxun.png",
          title: this.$t("case.projecList1_name"),
          tips: this.$t("case.projecList1_tips"),
          viewUrl: "/moXun",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/xueyuan.png",
          title: this.$t("case.projecList3_name"),
          tips: this.$t("case.projecList3_tips"),
          viewUrl: "/college",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/sanhj.png",
          title: this.$t("case.projecList2_name"),
          tips: this.$t("case.projecList2_tis"),
          viewUrl: "/sanhaiJing",
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100); // 延迟 100ms，确保页面加载完成
    this.slideInit();
    const wow = new WOW();
    wow.init();
  },
  methods: {
    /**
     * 滑动页面效果初始化
     */
    slideInit() {
      gsap.registerPlugin(ScrollTrigger);
      let panels = gsap.utils.toArray(".panel");
      panels.map((panel) =>
        ScrollTrigger.create({ trigger: panel, start: "top top" })
      );
      panels.forEach((panel) => {
        ScrollTrigger.create({
          trigger: panel,
          start: () =>
            panel.offsetHeight < window.innerHeight
              ? "top top"
              : "bottom bottom",
          pin: true,
          pinSpacing: false,
        });
      });
    },
    /**
     * 路由跳转方法
     * @param {*} id 文章id
     * @param {*} url 跳转路由
     */

    routerBtn(url, id) {
      if (id) {
        this.$router.push({ path: url, query: { id: id } });
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  background: #15171d;
  padding-top: 1.25rem;
}
.header_video {
  height: 100vh;

  .container_max {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header_title {
      position: absolute;
      top: 4.375rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      z-index: 2;
    }
  }
  .container_max::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 14.1875rem;
    position: absolute;
    top: 0;
    background: rgba(22, 39, 50, 0.74);
    z-index: 1;
  }
  .container_max::before {
    content: "";
    display: inline-block;
    width: 61.3125rem;
    height: 3.6875rem;
    background: #15171d;
    filter: blur(0.3375rem);
    position: absolute;
    // padding: 0 .5313rem;
    top: 12.1875rem;
    left: -0.5313rem;
    z-index: 2;
  }

  .video_box {
    object-fit: cover;
    width: 100vw;
    height: 14.1875rem;
    background: rgba(22, 39, 50, 0.74);
  }

  .header_video_bottom {
    width: 100vw;
    height: calc(100vh - (14.1875rem));
    padding: 0 5.875rem;
    padding-top: 1.75rem;
    align-items: flex-start;
    background: #15171d;

    .jb_title {
      background-image: -webkit-linear-gradient(
        70.4152078867778deg,
        #1d73ff 50%,
        #010711 100%,
        #15171d 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      height: 1.4063rem;
      line-height: 1.3125rem;
    }

    .xian {
      display: inline-block;
      height: 0.7813rem;
      border: 0.0313rem solid #bdbdbd;
      margin: 0 0.8125rem 0 0.5rem;
    }

    .left_box_tips {
      margin-top: 0.9375rem;
      width: 21.375rem;
      line-height: 1.3125rem;
      padding-bottom: 3.9688rem;
      text-align: justify;
    }

    .right_box {
      .col-lg-4:last-child(1) {
        .right_box_header {
          .max_title {
            border-right: 0.0313rem solid transparent;
          }
        }
      }

      .right_box_header {
        .max_title {
          border-right: 0.0313rem solid #bdbdbd;
          height: 0.7813rem;
          padding-right: 1rem;
        }
      }

      .max_title {
        margin-left: 0.7813rem;
      }

      .chlie_box {
        margin-top: 1.25rem;

        span {
          margin-bottom: 0.625rem;
        }
      }
    }
  }
}

.project_bj_box {
  background: #15171d;
  padding-top: 1.25rem !important;
  .container {
    margin: 0 13.5rem;
    max-width: calc(100vw - 27rem);

    .proj_bj_p1 {
      margin-top: 0.9375rem;
      margin-bottom: 1.5625rem;
    }

    .proj_bj_text {
      line-height: 1.3125rem;
      letter-spacing: 0.0313rem;
      text-align: justify;
    }

    .bj_image1,
    .bj_image3,
    .bj_image4 {
      background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/ninja/video_bj.png");
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      padding: 1.7188rem 0.9375rem;
      width: 100%;
      height: calc(19.375rem - (0.5625rem + 0.5625rem));

      .bj_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bj_image2 {
      height: 14.9063rem;

      .bj_image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .bj_image3 {
      height: 20.4375rem;
    }

    .bj_image4 {
      height: 16.6875rem;
    }
  }
}

.project_challenge_box {
  background: #15171d;
  padding-top: 4.6875rem;
  padding-bottom: 0;

  .container {
    margin: 0 0 0 13.5rem;
    max-width: calc(100vw - 13.5rem);

    .proj_bj_p1 {
      width: 32.5938rem;
      margin-top: 0.9375rem;
    }
  }

  // .carousel_box {
  //   background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/project_challenge_bj.png");
  //   background-size: 100% 100%;
  //   background-position: left bottom;
  //   background-position-x: -0.75rem;
  //   background-repeat: no-repeat;
  //   height: 19.4063rem;
  //   margin-top: 3.75rem;
  //   justify-content: flex-end;
  //   overflow: none;

  //   ::v-deep .el-carousel__container,
  //   .el-carousel--horizontal {
  //     // height: 21.875rem;
  //     overflow: visible;
  //   }

  //   ::v-deep .el-carousel__item {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: flex-end;
  //   }

  //   .carousel_item {
  //     // width: 100%;
  //     // height: 100%;
  //     justify-content: flex-end;
  //     // align-content: center;

  //     .carousel_img {
  //       width: 21.875rem;
  //       height: 14.875rem;
  //     }
  //   }
  // }

  .project_bj_image {
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/project_challenge_bj.png");
    background-size: 100% 100%;
    background-position: left bottom;
    background-position-x: -0.75rem;
    background-repeat: no-repeat;
    height: 19.4063rem;
    margin-top: 3.75rem;
    padding-bottom: 2.8125rem;
    justify-content: flex-end;
    overflow: none;
    display: flex;
    align-items: center;
    position: relative;

    .swiper_arr_icon {
      position: absolute;
      width: 2.5625rem;
      height: 2.5625rem;
      z-index: 9;
      right: 5.9375rem;
      &.btn-left {
        left: 5.9375rem;
        transform: rotate(180deg);
      }
    }

    .swiper-pagination {
      position: absolute;
      bottom: 1.125rem;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .swiper {
      height: 15.1563rem;
      width: 100%;
      overflow: unset !important;
    }

    .title_b {
      // margin-bottom: 1.5625rem ;
    }

    .img_box_image {
      width: 100%;
      height: 100%;
      margin-bottom: 1.375rem;
    }
  }
}

.project_summarize_box {
  background: #15171d;
  padding-top: 3.625rem;

  .container {
    margin: 0 13.5rem;
    max-width: calc(100vw - 27rem);

    .proj_bj_p1 {
      margin-top: 0.9375rem;
      margin-bottom: 1.5625rem;
    }

    .proj_bj_text {
      width: 29.0938rem;
      line-height: 1.3125rem;
      letter-spacing: 0.0313rem;
      text-align: justify;
    }
  }

  .customized_box {
    margin: 0 5.875rem;
    margin-top: 3.375rem;
    height: 7.9375rem;
    padding: 0 1.7813rem 0 1.7188rem;
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/dingzhi.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .tips_net {
      height: 0.8438rem;
    }

    .my_btn {
      width: 5.8125rem;
      height: 1.7813rem;
      border: 0.0313rem solid #ffffff;
      text-align: center;
      line-height: 1.7813rem;
    }
  }

  .case_box {
    margin: 0 5.875rem;
    margin-top: 5.3125rem;

    .case_list {
      margin-top: 0.9375rem;

      .case_item {
        width: calc(33.33% - 0.9rem);
        margin-right: 1.1875rem;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .case_image_box {
          width: 100%;
          height: 14.6875rem;
          margin-bottom: 0.625rem;
          overflow: hidden;

          .case_image {
            width: 100%;
            height: 100%;
            transition: all 0.8s;
          }
        }

        &:hover {
          .case_image {
            transform: scale(1.05);
            transition: all 0.8s;
          }
        }

        .case_title {
          height: 1.25rem;
        }

        &:nth-child(4) {
          margin: 0;
        }
      }
    }
  }
}

::v-deep .el-carousel__mask {
  background: transparent;
}

.carousel_item {
  width: 21.875rem;
  // height: 14.875rem;
}
.swiper-slideb {
  width: 100%;
  // height: 14.875rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: 10.4375rem !important;

  // &:nth-child(1){
  //   margin-left: 22.4375rem !important;
  // }
}
</style>
<style>
.swiper-pagination-bullet {
  background: #8a8e95 !important;
  margin-right: 0.875rem !important;
}
.swiper-pagination-bullet-active {
  background: #ffffff !important;
}
</style>
